@tailwind base;
@tailwind components;
@tailwind utilities;
ul {
  list-style: disc;
  margin-top: 10px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .sidebar-left {
    display: none;
  }
}

body{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}